const priceContracts: {cakeAddress: string, busdAddress: string, lpAddress:string} = {
  cakeAddress: '#', 
  busdAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  lpAddress: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019'
}

export default priceContracts


// const priceContracts: {cakeAddress: string, busdAddress: string, lpAddress:string} = {
//   cakeAddress: 'ACA VA EL TOKEN DE LA PAGINA', 
//   busdAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
//   lpAddress: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019'
// }

// export default priceContracts